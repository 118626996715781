import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { useStaticQuery, graphql, Link } from "gatsby"
import { makeStyles } from "@material-ui/core/styles"
import Button from "@material-ui/core/Button"

const useStyles = makeStyles(({ breakpoints, palette, spacing }) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "100vh",
  },
  content: {
    [breakpoints.down("md")]: {
      padding: spacing(8, 4),
    },
    width: "100%",
    maxWidth: 750,
    margin: "0 auto",
    padding: spacing(4, 8),
    textAlign: "center",
    "& > h1": {
      margin: "0 auto",
      paddingBottom: spacing(2),
      fontSize: "2.5em",
      color: palette.secondary.main,
    },
    "& > p": {
      fontSize: "1.1em",
    },
  },
}))

const AboutPage = () => {
  const classes = useStyles()

  const {
    markdownRemark: {
      html,
      frontmatter: { linkText },
    },
  } = useStaticQuery(graphql`
    query thanksQuery {
      markdownRemark(frontmatter: { id: { eq: "bedankt" } }) {
        html
        frontmatter {
          linkText
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO title="Bedankt" />
      <div className={classes.root}>
        <div
          dangerouslySetInnerHTML={{ __html: html }}
          className={classes.content}
        />
        <Button
          variant="contained"
          color="secondary"
          size="large"
          component={Link}
          to="/"
        >
          {linkText}
        </Button>
      </div>
    </Layout>
  )
}

export default AboutPage
